
import { defineComponent, reactive, ref, nextTick } from 'vue';
import { message } from 'ant-design-vue';
import {
    ManualParamsQueryListType,
    ManualParamsSearchListType,
} from '@/views/VehicleLicense/types';
import {
    getManualSearchResult,
    getRandomSearchResult,
    getNewSearchResult,
    markSampling,
} from '@/API/vl/vlSICCOChecking';
import { useStore } from 'vuex';
import { getRegionsbyBuList } from '@/API/rv/rvSicco';
import setColumnWidth from '@/utils/rv/setColumnWidth';
import cleanEmptyEleArray from '@/utils/rv/cleanEmptyElement';
import downloadFile from '@/utils/claim/downloadFile';

const columns = [
    { title: 'Is Sampled', dataIndex: 'isSampled', width: 100 },
    { title: 'Dealer Code', dataIndex: 'dealerCode', width: 100 },
    { title: 'Dealer Name', dataIndex: 'dealerName', width: 100 },
    { title: 'VIN', dataIndex: 'vinNo', width: 168 },
    { title: 'Plate No.', dataIndex: 'plateNo', width: 90 },
    { title: 'Registration Date', dataIndex: 'regDate', width: 130 },
    { title: 'Submission Date', dataIndex: 'submissionDate', width: 130 },
    { title: 'Make', dataIndex: 'make', width: 90 },
    { title: 'Brand', dataIndex: 'brand', width: 90 },
    { title: 'Customer', dataIndex: 'customer', width: 100 },
    { title: 'Retail Type', dataIndex: 'retailType', width: 104 },
    { title: 'Retail Date', dataIndex: 'retailDate', width: 130 },
    // {
    //   title: "Operation",
    //   fixed: "right",
    //   width: 90,
    //   slots: { customRender: "operation" },
    // },
];
const revisedArray = [
    { text: 'Y', value: '1' },
    { text: 'N', value: '0' },
    { text: 'All', value: '全部' },
];
const modeArray = ['单车上传', '批量上传'];
const tableWidth = window.innerWidth;
const tableHeight = window.innerHeight - 430;
export default defineComponent({
    setup() {
        const activeKey = ref<string>('1');
        const { state } = useStore();
        const buIdsArray: number[] = state.user.buList.map((item: any) => {
            return item.id;
        });
        // 表格展示
        const tableData = ref([]);
        const tabCon = ref();
        //分页组件
        const pagination = reactive({
            pageSize: 20,
            currentPage: 1,
            total: 0,
        });
        const regionArray = ref<any>([]);
        // 从SICCOChecking 页面点击sampling 按钮 进入页面时的查询参数
        const samplingCheckingDataCookie = sessionStorage.getItem(
            'samplingCheckingData'
        );
        const samplingCheckingData = {};
        if (samplingCheckingDataCookie) {
            Object.assign(
                samplingCheckingData,
                JSON.parse(samplingCheckingDataCookie)
            );
        }
        const samplingCheckingInitDataCookie = sessionStorage.getItem(
            'samplingCheckingInitData'
        );
        let allLicenseIdList: any;
        let allVehicleIdList: any;
        const samplingCheckingInitData = {};
        if (samplingCheckingInitDataCookie) {
            Object.assign(
                samplingCheckingInitData,
                JSON.parse(samplingCheckingInitDataCookie)
            );
            allLicenseIdList = (samplingCheckingInitData as any)
                .allLicenseIdList;
            allVehicleIdList = (samplingCheckingInitData as any)
                .allVehicleIdList;
            tableData.value = (samplingCheckingInitData as any).data;
            pagination.total = (samplingCheckingInitData as any).total;
            const customerColumn = ['dealerName', 'customer'];
            tableData.value.length &&
                Object.assign(
                    columns,
                    setColumnWidth(columns, tableData.value, customerColumn)
                );
        }
        const manualParams = reactive<ManualParamsQueryListType>({
            vinNo: '',
            vinNos: [],
            retailDatePeriod: [],
            region: '',
            dealerCode: '',
            isModified: '全部',
            submitMode: null,
        });
        const manualmanualParams = reactive<ManualParamsSearchListType>({
            vinNo: '',
            vinNos: [],
            retailStartDate: '',
            retailEndDate: '',
            region: '',
            dealerCode: '',
            isModified: '',
            submitMode: '',
        });
        let searchParams = {};
        const sampleSize = ref<string>('');
        // 批量复制
        const batchQuery = reactive({
            batchQueryTitle: '',
            batchQueryModal: false,
            batchQueryListTitle: '',
        });
        const textContentNo = ref<string>('');
        const textNoArray = ref<string[]>([]);
        const input = ref();
        const batchPlaceholder = ref(['', '', '', '', '', '']);
        // 获取区域数据
        const getArea = () => {
            getRegionsbyBuList({ params: { buIds: buIdsArray } }).then(
                (res: any) => {
                    const arr: any = [];
                    if (res.code === '0') {
                        if (res.data) {
                            res.data.forEach((item: any) => {
                                if (item.regionDto) {
                                    item.regionDto.forEach((itemCld: any) => {
                                        const obj = Object.assign({});
                                        obj.key = item?.id + '_' + itemCld?.id;
                                        obj.code = item?.id + '_' + itemCld?.id;
                                        obj.msg =
                                            item?.nameEn +
                                            '_' +
                                            itemCld?.regionEn;
                                        arr.push(obj);
                                    });
                                }
                            });
                        }
                        regionArray.value = arr;
                    } else {
                        message.error(res.message);
                    }
                }
            );
        };
        getArea();
        // 设置批量查询modal 的 textNoArray
        const setBatchQueryTextNoArray = () => {
            textNoArray.value =
                cleanEmptyEleArray(manualParams.vinNos)[0] === ''
                    ? []
                    : cleanEmptyEleArray(manualParams.vinNos);
        };
        // 设置批量查询modal 的 占位div
        const setBatchPlaceholder = () => {
            if (textNoArray.value.length) {
                batchPlaceholder.value = [];
            } else {
                batchPlaceholder.value = ['', '', '', '', '', ''];
            }
        };
        // 批量查询按钮事件
        const batchQueryEvent = () => {
            batchQuery.batchQueryModal = true;
            batchQuery.batchQueryTitle = '车架号批量查询';
            batchQuery.batchQueryListTitle = '车架号列表';
            setBatchQueryTextNoArray();
            setBatchPlaceholder();
            const dom = document.getElementsByClassName('list-content')[0];
            nextTick(() => {
                dom && dom.scrollTo(0, textNoArray.value.length * 36);
                input.value.focus();
            });
        };
        // 关闭批量查询Modal
        const closeBatchQueryModal = () => {
            manualParams.vinNo = manualParams.vinNos[0];
        };
        // 批量文本内容
        const textChangEvent = () => {
            if (textContentNo.value.length < 5) {
                message.error('请从Excel中粘贴到文本框');
                textContentNo.value = '';
                return;
            }
            textNoArray.value = textNoArray.value.concat(
                textContentNo.value
                    .replace(/\r|\n|\s/g, ',')
                    .split(',')
                    .filter((item) => item)
            );
            const dom = document.getElementsByClassName('list-content')[0];
            textContentNo.value = '';
            manualParams.vinNos = [];
            manualParams.vinNos = manualmanualParams.vinNos.concat(
                textNoArray.value
            );
            setBatchPlaceholder();
            nextTick(() => {
                dom.scrollTo(0, dom.scrollHeight + 40);
                input.value.focus();
            });
        };
        // 清除批量查询
        const clearBatchQuery = () => {
            manualParams.vinNos = [];
            setBatchQueryTextNoArray();
            setBatchPlaceholder();
            input.value.focus();
        };

        const getParams = () => {
            manualmanualParams.vinNo = '';
            manualmanualParams.vinNos = [];
            manualmanualParams.retailStartDate = '';
            manualmanualParams.retailEndDate = '';
            manualmanualParams.region = '';
            manualmanualParams.dealerCode = '';
            manualmanualParams.isModified = '';
            manualmanualParams.submitMode = '';
            if (manualParams.vinNo) {
                manualmanualParams.vinNo = manualParams.vinNo.trim();
            }
            if (manualParams.vinNos.length > 0) {
                manualmanualParams.vinNos = cleanEmptyEleArray(
                    manualParams.vinNos
                );
            }
            if (manualParams.retailDatePeriod.length > 0) {
                manualmanualParams.retailStartDate =
                    manualParams.retailDatePeriod[0];
                manualmanualParams.retailEndDate =
                    manualParams.retailDatePeriod[1];
            }
            if (manualParams.region) {
                manualmanualParams.region = manualParams.region;
            }
            if (manualParams.dealerCode) {
                manualmanualParams.dealerCode = manualParams.dealerCode.trim();
            }
            if (manualParams.isModified) {
                manualmanualParams.isModified = manualParams.isModified;
            }
            if (manualParams.submitMode) {
                manualmanualParams.submitMode = manualParams.submitMode;
            }
        };

        const getSearchParams = () => {
            searchParams = {};
            const json = samplingCheckingData;
            for (const key in json) {
                if (key == 'vinNo') {
                    if (manualmanualParams.vinNo) {
                        searchParams[key] = manualmanualParams.vinNo;
                    } else {
                        searchParams[key] = json[key];
                    }
                } else if (key == 'vinNos') {
                    if (manualmanualParams.vinNos.length > 0) {
                        searchParams[key] = manualmanualParams.vinNos;
                    } else {
                        searchParams[key] = json[key];
                    }
                } else if (key == 'retailStartDate') {
                    if (manualmanualParams.retailStartDate) {
                        searchParams[key] = manualmanualParams.retailStartDate;
                    } else {
                        searchParams[key] = json[key];
                    }
                } else if (key == 'retailEndDate') {
                    if (manualmanualParams.retailEndDate) {
                        searchParams[key] = manualmanualParams.retailEndDate;
                    } else {
                        searchParams[key] = json[key];
                    }
                } else if (key == 'dealerCode') {
                    if (manualmanualParams.dealerCode) {
                        searchParams[key] = manualmanualParams.dealerCode;
                    } else {
                        searchParams[key] = json[key];
                    }
                } else {
                    searchParams[key] = json[key];
                }
            }
        };

        const sampleSizeChange = () => {
            const reg = /^[1-9]*[1-9][0-9]*$/;
            if (sampleSize.value.trim()) {
                if (!reg.test(sampleSize.value.trim())) {
                    sampleSize.value = '';
                }
            }
        };

        const resetParams = () => {
            manualParams.vinNo = '';
            manualParams.vinNos = [];
            manualParams.retailDatePeriod = [];
            manualParams.region = '';
            manualParams.dealerCode = '';
            sampleSize.value = '';
        };

        const getNewRes = () => {
            // getNewSearchResult(searchParams).then((res: any): void => {
            //     tableData.value = res.content;
            //     pagination.total = res.totalElements;
            //     const customerColumn = ['dealerName', 'customer'];
            //     tableData.value.length &&
            //         Object.assign(
            //             columns,
            //             setColumnWidth(columns, tableData.value, customerColumn)
            //         );
            // });
            markSampling(searchParams).then((res: any): void => {
                tableData.value = res.pageData.content;
                pagination.total = res.pageData.totalElements;
                const customerColumn = ['dealerName', 'customer'];
                tableData.value.length &&
                    Object.assign(
                        columns,
                        setColumnWidth(columns, tableData.value, customerColumn)
                    );
            });
        };

        const getManualTable = () => {
            getParams();
            getSearchParams();
            searchParams['region'] = manualmanualParams.region;
            searchParams['current'] = 1;
            searchParams['size'] = pagination.pageSize;
            searchParams['isModified'] = manualmanualParams.isModified;
            searchParams['submitMode'] = manualmanualParams.submitMode;
            searchParams['licenseIdList'] = allLicenseIdList;
            searchParams['vehicleIdList'] = allVehicleIdList;
            getManualSearchResult(searchParams).then((res: any): void => {
                pagination.currentPage = 1;
                getNewRes();
                // tableData.value = res.content;
                // pagination.total = res.totalElements;
                // const customerColumn = ['dealerName', 'customer'];
                // tableData.value.length &&
                //     Object.assign(
                //         columns,
                //         setColumnWidth(columns, tableData.value, customerColumn)
                //     );
            });
        };

        const getRandomTable = () => {
            if (sampleSize.value.trim()) {
                getSearchParams();
                searchParams['sampleSize'] = sampleSize.value;
                searchParams['current'] = 1;
                searchParams['size'] = pagination.pageSize;
                searchParams['licenseIdList'] = allLicenseIdList;
                searchParams['vehicleIdList'] = allVehicleIdList;
                getRandomSearchResult(searchParams).then((res: any): void => {
                    pagination.currentPage = 1;
                    getNewRes();
                    // tableData.value = res.content;
                    // pagination.total = res.totalElements;
                    // const customerColumn = ['dealerName', 'customer'];
                    // tableData.value.length &&
                    //     Object.assign(
                    //         columns,
                    //         setColumnWidth(
                    //             columns,
                    //             tableData.value,
                    //             customerColumn
                    //         )
                    //     );
                });
            }
        };

        const useWhich = () => {
            getParams();
            getSearchParams();
            searchParams['isSampled'] = '';
            searchParams['current'] = pagination.currentPage;
            searchParams['size'] = pagination.pageSize;
            // if (activeKey.value == '1') {
            //     searchParams['region'] = manualmanualParams.region;
            // } else {
            //     searchParams['sampleSize'] = sampleSize.value;
            // }
            getNewRes();
        };

        const changeTab = () => {
            resetParams();
            pagination.currentPage = 1;
            pagination.pageSize = 20;
        };

        const exportSearchResult = () => {
            const urlPath = './rvapi/vl/sicco/sampleExport';
            if (activeKey.value == '1') {
                getParams();
                getSearchParams();
                searchParams['region'] = manualmanualParams.region;
            } else {
                getSearchParams();
                searchParams['sampleSize'] = sampleSize.value;
            }
            searchParams['vehicleIdList'] = allVehicleIdList;
            searchParams['current'] = pagination.currentPage;
            searchParams['size'] = pagination.pageSize;
            const downparams = {
                url: urlPath,
                method: 'post',
                params: searchParams,
                fileName: `${+new Date()}.xlsx`,
            };
            downloadFile(downparams, 'application/vnd-excel; char-set=UTF-8');
        };
        // 分页逻辑
        const pageChange = (page: number, pageSize: number) => {
            pagination.currentPage = page > 0 ? page : 1;
            pagination.pageSize = pageSize;
            useWhich();
        };
        const sizeChange = (page: number, pageSize: number) => {
            pagination.currentPage = page > 0 ? page : 1;
            pagination.pageSize = pageSize;
            useWhich();
        };

        return {
            tableWidth,
            columns,
            regionArray,
            activeKey,
            samplingCheckingDataCookie,
            samplingCheckingData,
            manualParams,
            manualmanualParams,
            searchParams,
            sampleSize,
            sampleSizeChange,
            getParams,
            getSearchParams,
            status,
            changeTab,
            tableData,
            pagination,
            useWhich,
            getManualTable,
            getRandomTable,
            pageChange,
            sizeChange,
            tableHeight,
            tabCon,
            batchQuery,
            textContentNo,
            textNoArray,
            input,
            batchPlaceholder,
            setBatchQueryTextNoArray,
            setBatchPlaceholder,
            batchQueryEvent,
            closeBatchQueryModal,
            textChangEvent,
            clearBatchQuery,
            resetParams,
            exportSearchResult,
            getNewRes,
            getArea,
            buIdsArray,
            revisedArray,
            modeArray,
        };
    },
});
